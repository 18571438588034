import moment from "moment";

export class FiscalYear {
    public values: number [] = []

    constructor() {
        const currentYear: number = moment().year()
        for (let i:number = 0; i <= 6; i++) {
            this.values.push(currentYear - i)
        }
    }
}
