export class ActivityType {

    public static values = [
        "AREA OF CONCERN",
        "AUDIT PREP",
        "AUDITS",
        "BED CHANGES",
        "BLSS",
        "CASEMIX PROFIENCY TESTING",
        "CHOW - FEDERAL",
        "CHOW - STATE (LIC./REG.)",
        "CLERICAL SURVEY 670",
        "CLERICAL SURVEY NON-670",
        "CLIA PROFICIENCY TESTING",
        "CLIA PROGRAM",
        "CMR INSTITUTE MENTAL DIS",
        "COMPLAINTS - CLOSED",
        "CONSTRUCTION INSPECTION",
        "CONSULTATION",
        "CONSULTATION (PROVIDERS)",
        "CORRESPONDENCE",
        "CRIMINAL TRIALS",
        "DAR-RO",
        "DAR-SA",
        "DOCUMENTATION",
        "ENGINEERING PACKET REVIEW",
        "EXP - AUDIT PREP",
        "EXP - AUDITS",
        "EXPENSE REPORT/TIME SHEET",
        "EXP - POST AUDITS",
        "EXP - TRAVEL",
        "FEDERAL BACKLOG CLOSE OUT",
        "FEDERAL INTAKE",
        "FEDERAL SHUTDOWN PLANNING",
        "FEDERAL TRAINING",
        "Follow-Up DOCUMENTATION",
        "General Administrative",
        "GENERAL CLERICAL",
        "HCAL 10 AFTER EXIT ORDERS",
        "HEARINGS/DEPOSITIONS",
        "HRD E-LICENSING PROJECT",
        "HRD REORGANIZATION PROJEC",
        "HWS (ALL HWS ACTIONS)",
        "HWS CLERICAL SUPPORT",
        "HWS INITIALS/RENEWALS",
        "IJ ABATEMENT DOC",
        "IJ ABATEMENT ONSITE EARLY",
        "IJ ABATEMENT ONSITE LATE",
        "IJ ABATEMENT ONSITE MID",
        "IJ ABATEMENT PREP",
        "IJ ABATEMENT SUPERVISORY",
        "IJ ABATEMENT TRAVEL",
        "INFO-GATHERING DOC",
        "INFO-GATHERING ON-SITE",
        "INFO-GATHERING PREP",
        "INFO-GATHERING - SUPERVIS",
        "INFO-GATHERING TRAVEL",
        "INFORMAL DISPUTE LAW-JUDG",
        "INFORMAL DISPUTE RESOLUTI",
        "INI SUR ON-SITE 12AM-8AM",
        "INI SUR ON-SITE 6PM-12AM",
        "INI SUR ON-SITE 8AM-6PM",
        "INITIALS - CERTIFICATION",
        "INITIALS - LICENSING",
        "INITIAL SURVEY DOCUMENT",
        "INITIAL SURVEY PREP",
        "INITIAL SURVEY TRAVEL",
        "INI TRAVEL & DOCUMENT",
        "INI TRAVEL & PREPARATION",
        "INTAKE",
        "IOC ICF/MR",
        "L&C 10 AFTER EXIT ORDERS",
        "LC 54 AFTER EXIT ORDERS",
        "L&C FED COMPLTS INTGRTION",
        "MALTREATMENT LETTERS",
        "MALTX HERING/R4R/CRIM TRL",
        "MISCELLANEOUS FEDERAL",
        "MISCELLANEOUS STATE",
        "NAR REGISTRY",
        "OHFC 10 AFTER EXIT ORDERS",
        "OHFC ON-SITE & TRAVEL",
        "OHFC PREP & DOCUMENTATION",
        "OLA AUDIT",
        "ON-SITE",
        "ON-SITE 6PM-12AM",
        "ON-SITE 8AM-6PM",
        "PCR ON-SITE 12AM-8AM",
        "PCR ON-SITE 6PM-12AM",
        "PCR ON-SITE 8AM-6PM",
        "PCR SURVEY PREPARATION",
        "PCR TRAVEL",
        "PCR TRAVEL & DOCUMENT",
        "PCR TRAVEL & PREPARATION",
        "PERFORMANCE REVIEWS",
        "PLAN REVIEW",
        "POST AUDIT",
        "PREPARE TO PROVIDE TRAINI",
        "PREP TIME",
        "PROFES/SELF-DEV TRAINING",
        "PROVIDER TRAINING",
        "PROVIDING TRAINING",
        "PRTF PLANNING",
        "RECEIVING TRAINING",
        "RECON. AT CENTRAL OFFICE",
        "RECON. AT FACILITY",
        "RENEWALS NOT HWS",
        "REVISE ST. SURVEY PROCESS",
        "SFM CONTRACT MAINTENANCE",
        "SNSA",
        "STAFF MEETINGS",
        "STATE INTAKE",
        "STATE-ONLY TRAINING",
        "STATE SHUTDOWN PLANNING",
        "STATE TRAINING",
        "STRIKE PLANNING",
        "SUPERVISING INI REVIEW",
        "SUPERVISING PCR REVIEW",
        "SUPERVISING REVIEW",
        "SUPERVISOR ON-SITE REVIEW",
        "SUR HEARINGS & DEPOSITION",
        "SURVEY MONITORING DOC",
        "SURVEY MONITORING EARLY",
        "SURVEY MONITORING LATE",
        "SURVEY MONITORING ON-SITE",
        "SURVEY MONITORING PREP",
        "SURVEY MONITORING SUP REV",
        "SURVEY MONITORING TRAVEL",
        "SURVEY PREPARATION",
        "SURVEY REVIEW WITH RO",
        "TRAVEL",
        "TRAVEL & DOCUMENTATION",
        "TRAVEL PREP",
        "TRAVEL & PREPARATION",
        "UNITY HEARING DISCOVERY",
        "WORKLOAD DEVELOPMENT",
    ];
}