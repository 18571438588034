export class County {
    public static values = [
        "Aitkin",
        "Anoka",
        "Becker",
        "Beltrami",
        "Benton",
        "Big Stone",
        "Blue Earth",
        "Brown",
        "Carlton",
        "Carver",
        "Cass",
        "Chippewa",
        "Chisago",
        "Clay",
        "Clearwater",
        "Cook",
        "Cottonwood",
        "Crow Wing",
        "Dakota",
        "Dodge",
        "Douglas",
        "Faribault",
        "Fillmore",
        "Freeborn",
        "Goodhue",
        "Grant",
        "Hennepin",
        "Houston",
        "Hubbard",
        "Isanti",
        "Itasca",
        "Jackson",
        "Kanabec",
        "Kandiyohi",
        "Kittson",
        "Koochiching",
        "Lac qui Parle",
        "Lake",
        "Lake of the Woods",
        "Le Sueur",
        "Lincoln",
        "Lyon",
        "Mahnomen",
        "Marshall",
        "Martin",
        "McLeod",
        "Meeker",
        "Mille Lacs",
        "Morrison",
        "Mower",
        "Murray",
        "Nicollet",
        "Nobles",
        "Norman",
        "Olmsted",
        "Otter Tail",
        "Pennington",
        "Pine",
        "Pipestone",
        "Polk",
        "Pope",
        "Ramsey",
        "Red Lake",
        "Redwood",
        "Renville",
        "Rice",
        "Rock",
        "Roseau",
        "Scott",
        "Sherburne",
        "Sibley",
        "Stearns",
        "Steele",
        "Stevens",
        "St. Louis",
        "Swift",
        "Todd",
        "Traverse",
        "Wabasha",
        "Wadena",
        "Waseca",
        "Washington",
        "Watonwan",
        "Wilkin",
        "Winona",
        "Wright",
        "Yellow Medicine"
    ]
}